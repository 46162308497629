/*
 *
 *   GULL - React Bootstrap Admin Template
 *
 *
*/

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~react-datetime/css/react-datetime.css";
@import "~rc-slider/assets/index.css";
@import "~rc-tooltip/assets/bootstrap.css";
@import "~react-quill/dist/quill.bubble.css";
@import "~react-quill/dist/quill.snow.css";
@import "~react-quill/dist/quill.core.css";
@import "~react-vis/dist/style";
@import "~ladda/dist/ladda.min.css";
@import "~react-notifications/lib/notifications.css";
@import "~sweetalert2/src/sweetalert2.scss";
@import "~react-image-crop/dist/ReactCrop.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "../../../node_modules/@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
@import "../iconsmind/iconsmind.css";
@import "variables";

// @import 'themes/lite-blue';
@import "themes/lite-purple.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "bootstrap-rtl.scss";
@import "globals/globals";

.profile-image img {
    width: 3.2rem;
    border-radius: 50%;
    width: 30px;
}

.active-membership {
  // transform: scale(1.1);
  transition: 0.4s ease-in-out;
  position: relative;
  z-index: 2;
  background-color: $gray-100;
}

.online-status {
  &:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    top: calc(50% - 4px);
    right: 30px;
    background: $light;
    border-radius: 50%;
}
  &.online {
      &:before {
          background: $success;
      }
  }
}

.rtl {
  direction: rtl;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table.table-responsive {
  min-height: 350px;
}
